<template>
  <div class="card">

    <div class="card-body">
      <template v-if="addSubTask">
        <h6 class="editLine subtask" ref="editMe" >{{
            subtask.title
          }}</h6>
      </template>

      <div class="taskWrap" v-for="task in task.subIssues"
           :class="(taskActive && taskActive.id === task.id) ? 'activeTask' : 'notActive'"
           :key="task.id">
        <div class="taskLineWrap">
          <div class="d-flex">

            <div class="lineitem l-title"  @click="this.$emit('triggerLogin')"  >
              <template v-if="task.parent_issue && !this.$route.params.id"><i class="fa-duotone fa-list-tree"></i></template> {{ task.title }}
            </div>
            <div class="lineitem l-subtasks"  >

              <template v-if="task._countIssues > 0"  @click="this.$emit('triggerLogin')" >
                <i class="fa-duotone fa-list-tree"></i><span> <span class="incNumber">{{ task._countIssues }}</span></span>
              </template>
            </div>
            <div class="lineitem l-attachments"  @click="this.$emit('triggerLogin')"  >
              <template v-if="task._countAttachments > 0">
                <i class="fa-duotone fa-paperclip"></i><span> <span class="incNumber">{{ task._countAttachments }}</span></span>
              </template>
            </div>

            <div class="lineitem l-comments" @click="this.$emit('triggerLogin')" >
              <template v-if="task._countComments > 0">
                <i class="fa-duotone fa-comments"></i><span> <span class="incNumber">{{ task._countComments }}</span></span>
              </template>
            </div>


            <div class="lineitem l-prio"><span :tooltip="task.issuePriorityTitle" :position="'left'"
                                               :style="'color:'+task.issuePriorityColor"><i class="fa-duotone"
                                                                                            :class="task.issuePriorityIcon"></i></span>
            </div>


            <div class="lineitem l-customer projects">
              {{task.projectTitle}}
            </div>
            <div class="lineitem l-date lastChange"
                 :tooltip="'Letzte Änderung'" :position="'left'">{{ $filters.formatDateTime(task._lastChange) }}
            </div>
            <div class="lineitem l-id">
              <small>{{task.id}}</small>
            </div>
            <div class="lineitem l-actions last">

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import List from "@/routing/core/Tasks/Sublist";
import Headline from "@/routing/core/Tasks/Layouts/Headline.vue";


export default {
  name: "Subtasks",
emits: ["triggerLogin"],
  props: {
    task: {
      type: Object
    }
  },
  components: {
    Headline,
    List
  },
  data() {
    return {
      subtasks: [],
      addSubTask: false,
      subtask: {
        title: ''
      }
    };
  },
  methods: {



  },
  mounted() {
  },
  created() {

  }
}
</script>
<style scoped>
.editLine.subtask{
  border-bottom: 1px solid #333;
  width: 100%;
  min-height: 24px;
}
</style>

<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100 show"
    id="sidenav-collapse-main"
  >
    <router-view name="filter"/>
  </div>
</template>
<script>

export default {
  name: "SidenavList",

};
</script>
<style lang="scss">

</style>

<template>
  <div class="card-header card-header-inline">
    <h6 class="editLine">Zeiterfassung für {{
        taskActive.title
      }}</h6>
    <div class="btn-group float-end">
      <button class="btn btn-sm btn-outline-primary" @click="addTime = true"><i class="fa-duotone fa-stopwatch"></i> Aufwand hinzufügen</button>
    </div>
  </div>
  <div class="card-body">
    <Modal v-if="addTime">
      <div class="modal show " style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content" style="min-height: 30vh;">
            <div class="modal-header">
              <button class="btn-close" @click="addTime = false"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-6 col-12">
                  <label class="color">Stunden</label>
                  <input type="number" v-model="time.hours" class="form-control small" step="0.25" />
                </div>
                <div class="col-lg-6 col-12">
                  <label class="color">Datum</label>
                  <VueDatePicker class="small" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                 placeholder="Datum" :auto-apply="true" :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('globals.choose')"
                                 v-model="time.date"></VueDatePicker></div>
                </div>
                <div class="row">
                  <div class="col-lg-8 col-12">
                    <label class="color">Kommentar</label>
                    <textarea v-model="time.comment" class="form-control small" ></textarea>
                  </div>
                  <div class="col-lg-4 col-12">
                    <label class="color">Aktivität</label>

                    <VueMultiselect class="small" :options="Object.values(getConfigs.issue_activities)"
                                    v-model="time.activity"
                                    :multiple="false" :valueProp="'id'" ref="multiselect_tracker"
                                    :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                    :isOpen="true" track-by="title" openDirection="bottom"
                                    :placeholder="this.$t('tasks.details.chooseActivity')" label="title" :allow-empty="false"
                                    :select-label="this.$t('tasks.details.chooseActivity')"
                    ></VueMultiselect>

                  </div>
              </div>
</div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import VueMultiselect from 'vue-multiselect';
export default {
  name: "Timesheet",
  props: {
  },
  components: {
    VueDatePicker, VueMultiselect
  },
  computed: {
    ...mapGetters(["modalsList", "taskActive", "getConfigs"])
  },
  data() {
    return {
      file: '',
      addTime: false,
      time: {
        date: new Date().toISOString().substr(0, 10),
        hours: '1.00',
        activity: '',
        comment: '',
      }
    };
  },
  methods: {

  }
}
</script>

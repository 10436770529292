<template>
  <div class="card">
    <div class="card-header card-header-inline">
      <h6 class="editLine" v-if="taskActive">{{
          taskActive.title
        }}</h6>
      <div class="btn-group float-end">
        <button class="btn btn-outline-primary btn-sm" @click="showCommentNew = true; editComment = false;" v-if="!showCommentNew"><i
          class="fa-duotone fa-comment"></i> {{$t("tasks.comments.newComment")}}
        </button>
        <button class="btn btn-close" @click="showCommentNew = false" v-if="showCommentNew"> </button>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Createcomment :task-id="taskId" @close="closeComment" v-if="showCommentNew"/>
        </div>
      </div>
      <div class="comments" v-if="taskActive &&  taskActive.comments && Object.keys(taskActive.comments).length > 0">
        <div class="row">
          <div class="col">
            <div class="list-group">
              <template v-for="(times, time) in taskActive.history">
                <template v-for="(comment, historyType) in times">
                <div class="list-group-item" :class="(getUser.id === comment.crm_created_user_id) ? 'own' : 'other'" :key="comment.id" v-if="historyType === 'comment' && comment.comment">
                  <template v-if="( comment.id === setComment.id ) && editComment">
                    <Editor2 v-model="setComment.comment"></Editor2>
                    <div class="row">
                      <div class="col">
                        <button @click="updateComment" class="btn btn-outline-primary btn-sm ms-1"><i
                          class="fa-duotone fa-paper-plane"></i> {{$t("globals.send")}}
                        </button>
                        <button @click="editComment = false; setComment = {};"
                                class="btn btn-outline-secondary btn-sm ms-3"><i
                          class="fa-duotone fa-x"></i> {{$t("globals.cancel")}}
                        </button>

                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="inlineCommentWrap"  :class="(comment.official === 'official') ? 'official': ''">
                      <div class="infoLine w-100">
                        <template v-if="comment.crm_created_employee_id">
                        <small class="avatar"><img style="max-width: 40px"
                                                   :src="$filters.employeeIcon(getEmployees, comment.crm_created_employee_id, 'id')"
                                                   class="sketchAvatar"><b>
                          {{ $filters.employeeName(getEmployees, comment.crm_created_employee_id) }}</b></small>&nbsp;<small>{{
                          $filters.formatDateTime(comment.crm_created_date)
                        }}</small>
                        </template>
                        <template v-else>
                          <small class="avatar"><img style="max-width: 40px"
                                                     :src="$filters.generateSVGFromName(comment.author)"
                                                     class="sketchAvatar"><b class="color">
                            {{ comment.author }}</b></small>&nbsp;<small>{{
                            $filters.formatDateTime(comment.crm_created_date)
                          }}</small>
                        </template>
                        <template v-if="getUser.id === comment.crm_created_user_id">
                          <button class="btn btn-outline-primary btn-xs float-end ms-3" :tooltip="this.$t('tasks.comments.editComment')" position="left" @click="showCommentNew = false;setUpdateComment(comment)" style="margin-top: -3px;"><i class="fa-duotone fa-edit" ></i></button>


                        <button class="btn btn-outline-secondary btn-xs float-end ms-1" :tooltip="this.$t('tasks.comments.deleteComment')" position="left"  @click="deleteComment(comment.id)" style="margin-top: -3px;"><i class="fa-duotone fa-trash" ></i></button>
                        </template>

                      </div>
                      <div class="mb-1 isComment" v-html="$filters.renderLineBreaks(comment.comment)"></div>
                    </div>
                  </template>
                </div>
                <template v-else>
                  <div class="list-group-item onlyState" v-if="comment.change_type === 'customFile'">

                    <div class="inlineCommentWrap " style="cursor: pointer;" @click="$router.push({name: 'TasksTabs', params:{ id: comment.issue_id, tab: 'attachments'}})">
                      <div class="infoLine w-100">
                        <template v-if="comment.crm_created_employee_id">
                          <small class="avatar me-2"><img style="max-width: 40px"
                                                          :src="$filters.employeeIcon(getEmployees, comment.crm_created_employee_id, 'id')"
                                                          class="sketchAvatar">
                            {{ $filters.employeeName(getEmployees, comment.crm_created_employee_id) }}</small>&nbsp;
                        </template>
                        <template v-else>
                          <small class="avatar"><img style="max-width: 40px"
                                                     :src="$filters.generateSVGFromName(comment.author)"
                                                     class="sketchAvatar">
                            {{ comment.author }}</small>&nbsp;
                        </template>

                        <template v-if="comment.change_type === 'customFile'"><i class="fa-duotone fa-paperclip"></i></template>

                        <span class="inline-comment" v-html="$filters.renderLineBreaks(comment.comment)"></span><small>{{ $filters.formatDateTime(comment.crm_created_date)}}</small>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              </template>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Createcomment from "@/routing/core/Tasks/Layouts/Createcomment.vue";
import {mapGetters} from "vuex";
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";

export default {
  name: "Comments",
  props: {
    taskId: ""
  },
  emits: ["update"],
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getUser"])
  },
  components: {
    Editor2,
    Createcomment
  },
  data() {
    return {
      editComment: false,
      setComment: {},
      showCommentNew: false,
    }
  },
  methods: {
    setUpdateComment(comment) {
      this.setComment = comment;
      this.editComment = true;
    },
    closeComment() {
      this.showCommentNew = false;
    },
    deleteComment(commentId){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let VueInner = this;
      this.showDropdown = false;
      this.$confirm(VueInner.$t('tasks.comments.commentsTextDelete'), this.$t('tasks.comments.deleteComment'), 'warning', {
        confirmButtonText: this.$t('globals.yes'),
        cancelButtonText: this.$t('globals.no'),
        type: 'warning'
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment/" + commentId, {headers: headers})
          .then(() => {
            this.editComment = false;
            this.$emit("update");
          })
          .catch((error) => {
            console.log(error);
          });
      }).catch((r) => {
        console.log(r);
      });
    },
    updateComment() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};


      let postContent = {
        data: this.setComment,
      }
      axios.put(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment/" + this.setComment.id, postContent, {headers: headers})
        .then((response) => {

          this.editComment = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openTicket(ticket){
      const ticket_id = ticket.detail.id;
      this.$router.push({name: 'TasksTabs', params:{ id: ticket_id, tab: 'details'}});

    }
  },
  created() {

    window.addEventListener("triggerOpenTicket", this.openTicket);
  },
  beforeDestroy() {
    window.removeEventListener("triggerOpenTicket", this.openTicket);
  }
}
</script>
<style scoped>
</style>

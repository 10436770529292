<template>
  <template v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched[0].name === 'Tasks'">
      <button class="btn btn-outline-primary purple btn-sm me-3" :tooltip="$t('add.task')" position="left"
              @click="$store.dispatch('setActiveCustomer', null);$store.commit('openModal', 'task'); $store.state.activeItems.task = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.task">
        <div class="modal-dialog modal-xl purpleView">
          <div class="modal-content">
            <component :is="'DetailsT'"/>
          </div>
        </div>
      </Modal>
    </template>
  </template>
</template>
<script>

import {mapGetters} from "vuex";

import DetailsT from "@/routing/core/Tasks/Create.vue";

export default {
  name: "plusbutton",
  data() {
    return {
      activeComponent: 'Edit'
    };
  },
  components: {

    DetailsT,

  },
  computed: {
    ...mapGetters(["modalsList", 'getUser'])
  },
  methods: {

  }
}
</script>
<style scoped>
</style>

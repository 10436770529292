import {createStore} from "vuex";

import {
  state as activeItemsState,
  mutations as activeItemsMutations,
  actions as activeItemsActions,
  getters as activeItemsGetters
} from '@/store/objects/activeItems.js'
import {
  state as systemState,
  mutations as systemMutations,
  actions as systemActions,
  getters as systemGetters
} from '@/store/objects/system.js'
import {
  state as modalsState,
  mutations as modalsMutations,
  actions as modalsActions,
  getters as modalsGetters
} from '@/store/objects/modals.js'
import {
  state as listsState,
  mutations as listsMutations,
  actions as listsActions,
  getters as listsGetters
} from '@/store/objects/lists.js'
import {
  state as filtersState,
  mutations as filtersMutations,
  actions as filtersActions,
  getters as filtersGetters
} from '@/store/objects/filters.js'
import {
  state as pluginsState,
  mutations as pluginsMutations,
  actions as pluginsActions,
  getters as pluginsGetters
} from '@/store/objects/plugins.js'
import {actions as axiosGetActions} from '@/store/axios/get.js'

export default createStore({
  state: {
    ...systemState,
    ...activeItemsState,
    ...modalsState,
    ...listsState,
    ...filtersState,
    ...pluginsState,
  },
  mutations: {
    ...systemMutations,
    ...activeItemsMutations,
    ...modalsMutations,
    ...listsMutations,
    ...filtersMutations,
    ...pluginsMutations,
  },
  actions: {
    ...systemActions,
    ...activeItemsActions,
    ...filtersActions,
    ...axiosGetActions,
    ...modalsActions,
    ...listsActions,
    ...pluginsActions
  },
  getters: {
    ...pluginsGetters,
    ...activeItemsGetters,
    ...listsGetters,
    ...modalsGetters,
    ...systemGetters,
    ...filtersGetters
  }
});

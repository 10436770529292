<template>
  <div class="card" v-if="taskItem">
    <div class="card-header">
      <button type="button" class="btn-close float-end" aria-label="Close" @click="closeTask"></button>
      <template v-if="taskItem && taskItem.breadcrumbs && taskItem.breadcrumbs.length > 0">
        <div class="breadcrumbsWrap">
          <template v-for="(breadcrumb,key) in taskItem.breadcrumbs">
    <span class="breadrumb-item" :class="'level_'+key"
          @click="$router.push({name: 'TasksTabs', params: {id: breadcrumb.id, tab: 'details'}})">
      <template v-if="key > 0">
        <i class="fa-duotone fa-chevron-right ms-1 me-1"></i>
      </template>
      {{ breadcrumb.title }}</span>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="breadcrumbsWrap">
          <span class="breadrumb-item" @click="changeTab('details')">{{ taskItem.title }}</span>
        </div>
      </template>
    </div>
    <ul class="tabNav">
      <li v-for="cat in taskTabs" :key="cat.name"
          @click="changeTab(cat.name)"
          :class="(activeSubtab === cat.name) ? 'active' : ''">
        <template v-if="cat.name === 'comments' && taskItem._countComments"><span
          class="incNumber">{{ taskItem._countComments }}</span></template>
        <template v-if="cat.name === 'attachments' && taskItem._countAttachments "><span
          class="incNumber">{{ taskItem._countAttachments }}</span></template>
        <template v-if="cat.name === 'subtasks' && taskItem._countIssues"><span
          class="incNumber">{{ taskItem._countIssues }}</span></template>
        <template v-if="cat.name === 'timesheet' && taskItem.times && taskItem.times.sumTask"><span class="incNumber"
                                                                                                    style="width: auto;">{{
            taskItem.times.sumTask.toFixed(0)
          }}</span></template>
        <i class="fa-duotone" :class="cat.icon"></i>
        {{ cat.label }}
      </li>
    </ul>
    <div class="card-header card-header-inline" v-if="activeSubtab === 'details'">
      <div class="row">
        <div class="col-12"><h6>{{ taskItem.title }}</h6></div>

      </div>
    </div>
    <div class="card-body" v-if="activeSubtab === 'details'">
      <div class="taskDetails">

        <div class="infoBlock text-xs mb-2">
          <template v-if="taskItem.state_id !== '8'"><button class="btn btn-sm me-2 btn-outline-primary" @click="checkTask()"><i class="fa-duotone fa-check"></i> als erledigt markieren</button></template>
          <template v-else>
            <button disabled class="btn btn-sm me-2 btn-primary"><i class="fa-duotone fa-check"></i> Ticket erledigt</button>
          </template>

          <i
          class="fa-duotone fa-calendar-circle-plus"></i> {{ $t("tasks.details.created") }}
          {{ $filters.formatDate(taskItem.crm_created_date) }} |
          <i
            class="fa-duotone fa-calendar-circle-user"></i> {{ $t("tasks.details.processed") }}
          {{ $filters.formatDateTime(taskItem.crm_modified_date) }}
        </div>
        <div class="row mb-2">
          <div class="col-lg-9 col-xl-10 col-12">
            <div class="topInfos">
              <div class="row">
                <div class="col">
                  <label class="color">{{ $t("globals.customer") }}</label>
                  <template v-if="taskItem.customer">
                    <template v-if="taskItem.customer.c_company">
                      {{ taskItem.customer.c_company }}
                      <br><span class="text-xs customerInfo"
                                v-if="taskItem.customer.c_title || taskItem.customer.c_firstname || taskItem.customer.c_lastname">
           <template v-if="taskItem.customer.c_title">{{ taskItem.customer.c_title }}&nbsp;</template>
          <template v-if="taskItem.customer.c_firstname">{{ taskItem.customer.c_firstname }}&nbsp;</template>
          <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
              </span>
                    </template>
                    <template v-else>
                      <template v-if="taskItem.customer.c_title">{{ taskItem.customer.c_title }}&nbsp;</template>
                      <template v-if="taskItem.customer.c_firstname">{{
                          taskItem.customer.c_firstname
                        }}&nbsp;
                      </template>
                      <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
                    </template>
                  </template>
                </div>
                <div class="col" v-if="taskItem.project">
                  <label class="color">{{ $t("globals.project") }}</label>
                  <span>{{
                      taskItem.project.title
                    }}</span>
                </div>
                <div class="col">
                  <label class="color">{{ $t("globals.priority") }}</label>
                  <template v-if="editPrio">
                    <div style="position: relative;">
                      <button class="btn-close" @click="editPrio = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                      <VueMultiselect class="small open" :options="Object.values(preparePrios)"
                                      v-model="taskItem.priority_id"
                                      :multiple="false" :valueProp="'id'" ref="multiselect_prio"
                                      :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                      :isOpen="true" track-by="title" openDirection="bottom"
                                      :placeholder="this.$t('tasks.choosePriority')" label="title"
                                      :allow-empty="false"
                                      :select-label="this.$t('tasks.choosePriority')"
                                      @update:model-value="correctId('priority_id')"
                      ></VueMultiselect>
                    </div>
                  </template>
                  <template v-else>
                    <p>
                <span
                  @click="editPrio = true;$nextTick(() => $refs.multiselect_prio.activate())" style="cursor: pointer;"><i class="fa-duotone fa-edit"></i> {{
                    getTitleById(taskItem.priority_id, "issue_prios")
                  }}</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <hr>
            <template v-if="taskItem.descVisible === '1'">
            <label class="color">{{ $t("globals.description") }}</label>
            <div class="taskDesc" v-html="modifiedHtml"></div>
            <hr>
            </template>
            <div class="row infoRow">
              <div class="col">
              </div>
              <div class="col">
              </div>
              <template v-if="taskItem.estimated_hours && taskItem.estimated_hours > 0">
                <div class="col">
                  <label class="color">{{ $t("tasks.details.timeExposure") }}</label>
                  <input type="number" class="form-control hoverShowInput" v-model="taskItem.estimated_hours"
                         @change="updateTask"
                         style="width: 75px; display: inline-block; margin-left: 10px; margin-right: 10px;"/>
                </div>
              </template>
            </div>
          </div>
          <div class="col-lg-3 col-xl-2 col-12 rightCol">
            <label class="color">{{ $t("tasks.details.assignedTo") }}</label>
            <template v-if="!editAssignee">
              <p><img style="max-width: 40px"
                      v-if="getEmployees && getEmployees.length && taskItem && taskItem.employee_id"
                      :src="$filters.employeeIcon(getEmployees, taskItem.employee_id, 'id')"
                      class="sketchAvatar me-1">
                <template v-if="taskItem && taskItem.employee_id">
                  {{ $filters.employeeName(getEmployees, taskItem.employee_id) }}
                </template>
                <template v-else>
                  -
                </template>
              </p>
            </template>
            <label class="color">{{ $t("tasks.details.createdBy") }}</label>
            <p v-if="taskItem.crm_created_employee_id"><img style="max-width: 40px"
                                                            :src="$filters.employeeIcon(getEmployees, taskItem.crm_created_employee_id, 'id')"
                                                            class="sketchAvatar">
              {{ $filters.employeeName(getEmployees, taskItem.crm_created_employee_id) }}</p>
            <p v-else>
              <template v-if="taskItem.author">
                <img style="max-width: 40px;margin-right: 6px;"
                     :src="$filters.generateSVGFromName(taskItem.author)"
                     class="sketchAvatar"> {{ taskItem.author }}
              </template>
              <template v-else>{{ $t("globals.customer") }}</template>
            </p>
            <label class="color">{{ $t("globals.tracker") }}</label>
            <p v-if="taskItem.tracker_id">{{
                getTitleById(taskItem.tracker_id, "issue_trackers")
              }}</p>
            <label class="color">{{ $t("tasks.details.contactPerson") }}</label>
            <p  @click="editExtFollower = true;"
                style="cursor: pointer;">
              <template v-if="taskItem.followers && taskItem.followers.outer && taskItem.followers.outer.length">
                <i class="fa-duotone fa-edit"></i>
                <template v-for="follower in taskItem.followers.outer">
                  <span style="display: block;">{{ follower._customerName }}</span>
                </template>
              </template>
              <template v-else>
                <template v-if="taskItem.customer">
                  <i class="fa-duotone fa-edit"
                     v-if="taskItem.customer.c_firstname && taskItem.customer.c_lastname"></i>&nbsp;
                  <template v-if="taskItem.customer.c_firstname">{{ taskItem.customer.c_firstname }}&nbsp;</template>
                  <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
                </template>
              </template>
            </p>
            <div class="externalResource mb-3 col">
              <label class="color">{{ $t("tasks.taskNr") }}</label>
              <p class="mb-0">{{ taskItem.id }}/{{ taskItem.hash }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="taskAttachments" v-if="activeSubtab === 'attachments'">
      <Attachments :attachments="taskItem.attachments" v-if="taskItem.attachments"></Attachments>
    </div>
    <div class="subTasks" v-if="activeSubtab ==='subtasks'">
      <Subtasks :task="taskItem" v-if="taskItem"></Subtasks>
    </div>
    <div class="taskComments" v-if="activeSubtab === 'comments'">
      <Comments :taskId="taskItem.id" v-if="taskItem.id"/>
    </div>
    <div class="timeSheet" v-if="activeSubtab === 'timesheet'">
      <Timesheet taskId="taskItem.id" v-if="taskItem.id"/>
    </div>
  </div>
  <Modal v-if="editExtFollower">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg purpleView">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ansprechpartner ändern</h5>
            <button type="button" class="btn-close" style="margin-left: 24px;" @click="
      this.updateTask();editExtFollower = false"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="follower in addresses">
                <template v-if="follower.customer_joomla &&  getUser && follower.customer_joomla !== getUser.id">
                <div class="col-6">
                  <div class="prioSetter">
                    <!-- check if button is in array, if true then set it active with btn-primary -->
                    <template v-if="taskItem.followers.outer.some(item => item.id === follower.id)">
                      <button type="button" class="btn mb-2 w-100 btn-primary btn-sm  "
                              @click="setExtFollower(follower)"><span class="longName">{{ follower.c_title }} {{
                          follower.c_firstname
                        }} {{ follower.c_lastname }}<br><small>{{ follower.c_email }}</small></span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn mb-2 w-100 btn-outline-primary btn-sm  "
                              @click="setExtFollower(follower)"><span class="longName">{{ follower.c_title }} {{
                          follower.c_firstname
                        }} {{ follower.c_lastname }}<br><small>{{ follower.c_email }}</small></span></button></template>
                  </div>
                </div></template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="modalsList.previewFile">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-xl purpleView">
        <div class="modal-content" v-if="!loading">
          <template v-if="file">
            <div class="modal-header">
              {{ file.title }}
              <button type="button" class="btn-close" @click="closeFile()"></button>
            </div>
            <div class="modal-body">
              <template v-if="file.contentType ==='application/pdf'  ">
                <embed style="min-height: 80vh;" :src="'data:'+file.contentType+';base64,'+file.content">
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-12 text-center">
                    <div class="btn-group">
                      <button @click="zoomIn" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-plus"></i></button>
                      <button @click="zoomOut" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-minus"></i></button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <p class="mb-1"><small><i class="fa-duotone fa-computer-mouse-scrollwheel color-purple"></i> Das
                      Mausrad kann zum Zoomen verwendet werden</small>
                    </p></div>
                </div>
                <div class="row"  @wheel.prevent="handleWheel($event)">
                  <div class="col-12" style="overflow: hidden;">
                    <img :src="'data:'+file.contentType+';base64,'+file.content"
                         :style="{ transform: `scale(${zoomLevel})`, transformOrigin: transformOrigin }"
                         style="display: block; width: 100%; height: auto;">
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="modal-content" v-else>
          <div class="modal-header">
            <h5 class="modal-title">Datei wird geladen..</h5>
            <button type="button" class="btn-close" @click="closeFile()"></button>
          </div>
          <div class="modal-body">
            <preLoader type="inner" :loading="loading" height="300px" style="background: #fff;"/>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import Comments from "@/routing/core/Tasks/Comments.vue";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import Attachments from "@/routing/core/Tasks/Layouts/Attachments.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import VueMultiselect from 'vue-multiselect';
import Subtasks from "@/routing/core/Tasks/Layouts/Subtasks.vue";
import Timesheet from "@/routing/core/Tasks/Layouts/Timesheet.vue";
import $ from "jquery";
import preLoader from "@/components/Loading.vue";
export default {
  name: "Details",
  components: {
    preLoader,
    Comments, Editor2, Attachments, VueDatePicker, VueMultiselect, Subtasks, Timesheet
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getConfigs", "getUser"]),
    modifiedHtml() {
      if(this.taskItem && this.taskItem.description){
      return this.taskItem.description.replace(
        /\{attach:(.*?)\}/g,
        `<span onclick="handleClick('$1')" class="btn btn-outline-primary btn-sm">Datei öffnen $1</span>`
      );
      } else {
        return '';
      }
    },
    preparePrios(){
      return this.getConfigs.issue_prios.filter(prio => prio.official === 'yes');
    }
  },
  data() {
    return {
      taskItem: {},
      file: '',
      loading: false,
      zoomLevel: 1,
      transformOrigin: 'center center',
      editorVisible: true,
      taskTabs: [
        {name: 'details', label: this.$t('tasks.labels.details'), icon: 'fa-memo-circle-info'},
        {name: 'subtasks', label: this.$t('tasks.labels.subtasks'), icon: 'fa-list-tree'},
        {name: 'attachments', label: this.$t('tasks.labels.attachments'), icon: 'fa-paperclip'},
        {name: 'comments', label: this.$t('tasks.labels.comments'), icon: 'fa-comments'},
        // {name: 'timesheet', label:'Zeitaufwand', icon: 'fa-stopwatch'},
      ],
      activeSubtab: 'details',
      editStart: false,
      editDue: false,
      editAssignee: false,
      editState: false,
      editPrio: false,
      editTracker: false,
      editExtFollower: false,
      editIntFollower: false,
      editProject: false,
      editCustomer: false,
      addresses: [],
      projects: [],
      channel: null,
      pusher: null,
    }
  },
  methods: {
    closeTask() {
      this.$store.dispatch("tasksFilter");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Tasks"});
    },
    getSingleTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if(this.$route.params.id === undefined){
        return;
      }
      axios.get(this.$store.state.apiUrl + 'issue/' + this.$route.params.id, {headers})
        .then(response => {
          this.taskItem = response.data.result.data;
          this.taskItem.description = this.$filters.renderLineBreaks(this.taskItem.description);
          this.$store.dispatch('setTask', this.taskItem);
          this.getAddresses();
          this.getProjects();
          this.scrollToTop();
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
          this.closeTask();
        });
    },
    setExtFollower(follower) {
      const index = this.taskItem.followers.outer.findIndex(item => item.id === follower.id);
      if (index !== -1) {
        // If the follower is already in the array, remove them
        this.taskItem.followers.outer.splice(index, 1);
      } else {
        // If the follower is not in the array, add them
        this.taskItem.followers.outer.push(follower);
      }

    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.taskItem.customer.id + "&filter_state=active", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
    },
    getTitleById(id, type) {
      if (this.getConfigs && this.getConfigs[type]) {
        return Object.values(this.getConfigs[type]).find(issueState => issueState.id === id)?.title || 'keine';
      }
    },
    getAddresses() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.taskItem.crm_customer_id + "/addresses", {headers}).then((r) => {
        this.addresses = r.data.result.data;
      });
    },
    correctId(key) {
      this.taskItem[key] = this.taskItem[key].id;
      this.updateTask();
      this.editAssignee = false;
      this.editState = false;
      this.editTracker = false;
      this.editPrio = false;
      this.editProject = false;
    },
    editItem(field) {
      const value = event.target.innerText;
      this.taskItem[field] = value;
      this.updateTask();
    },
    updateTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.taskItem.customer.hash;
      let postContent = {
        data: this.taskItem,
        hash: hash
      }
      axios.put(this.$store.state.apiUrl + 'issue/' + this.taskItem.id, postContent, {headers})
        .then(response => {
          this.taskItem = response.data.result.data;
          this.$store.dispatch('setTask', this.taskItem);
          this.editStart = false;
          this.editDue = false;
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e);
        });
    },
    checkTask() {

      //in getConfigs.issue_states array find the object where issue_default_close is 'yes' and get the id, set the id to default_checked_id
      let default_checked_id = 8;
      this.getConfigs.issue_states.forEach((state) => {
        if (state.issue_default_close === 'yes') {
          default_checked_id = state.id;
        }
      });
      this.taskItem.state_id = default_checked_id;
      // toast success Ticket geschlossen
      this.$store.dispatch('toastSuccess', this.$t('toast.success.taskClosed'));
      this.updateTask();
      this.$router.push({name: "Tasks"});

      // Await refresh then this.closeTask
      setTimeout(() => {
        this.closeTask();
      }, 1000);
    },

    deleteTask(id) {
      this.$confirm(this.$t('tasks.details.detailsTextDelete'), this.$t('tasks.deleteTask'), {
        confirmButtonText: this.$t('globals.yes'),
        cancelButtonText: this.$t('globals.no'),
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + 'issue/' + id, {headers})
          .then(response => {
            this.$store.dispatch('setTask', null);
            this.$router.push({name: "Tasks"});
            this.$store.dispatch('toastSuccess', this.$t('toast.success.taskDeleted'));
          })
          .catch(e => {
            this.$store.dispatch("handleApiResponse", e.response);
          });
      }).catch(e => {
        console.log(e);
      });
    },
    setActiveSubtabFromRoute() {
      const {tab} = this.$route.params;
      if (tab && this.isValidTab(tab)) {
        this.activeSubtab = tab;
      } else {
        this.activeSubtab = 'details';
      }
    },
    isValidTab(tab) {
      return this.taskTabs.some((taskTab) => taskTab.name === tab);
    },
    changeTab(tabName) {
      this.activeSubtab = tabName;
      this.$router.push({name: 'TasksTabs', params: {id: this.$route.params.id, tab: tabName}});
    },
    hideEditor() {
      this.editorVisible = false;
    },
    showEditor() {
      this.editorVisible = true;
    },
    openAttachment(attachment) {

      const attachmentId = attachment.detail.id;
      const issueId = this.taskItem.id;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.loading = true;
      this.$store.commit('openModal', 'previewFile');
      axios.get(this.$store.state.apiUrl + "issue/" + issueId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          this.loading = false;
          // show in modal
        })
        .catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response);
          this.loading = false;
          // close modal
          this.$store.commit('closeModal', 'previewFile');
        });
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    zoomIn() {
      this.zoomLevel += 0.1;
    },
    zoomOut() {
      if (this.zoomLevel > 0.8) this.zoomLevel -= 0.1;
    },
    handleWheel(event) {
      const target = event.target; // This should be your image or embed element
      const rect = target.getBoundingClientRect();
      // Calculate cursor position inside the element as a percentage
      const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
      const yPercent = ((event.clientY - rect.top) / rect.height) * 100;
      // Update transformOrigin based on cursor position
      this.transformOrigin = `${xPercent}% ${yPercent}%`;
      // Zoom in or out
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    openTicket(ticket){
      const ticket_id = ticket.detail.id;
      this.$router.push({name: 'TasksTabs', params:{ id: ticket_id, tab: 'details'}});
    }
  },
  created() {
    this.getSingleTask();
    this.setActiveSubtabFromRoute();
    window.addEventListener("triggerReloadFiles", this.getSingleTask);
    window.addEventListener("triggerOpenAttachment", this.openAttachment);
    window.addEventListener("triggerOpenTicket", this.openTicket);
  },
  mounted() {
    Pusher.logToConsole = false;
    this.pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
    let VueInner = this;
    this.channel = this.pusher.subscribe(this.$store.state.apiToken);
    if(this.$route.params.id){
    this.channel.bind('issue-' + this.$route.params.id, function (data) {
      VueInner.getSingleTask();
    });
    }
  },
  beforeUnmount() {
    if (this.channel) {
      this.channel.unbind('issue-' + this.$route.params.id);
      this.pusher.unsubscribe(this.$store.state.apiToken);
    }

      window.removeEventListener("triggerReloadFiles", this.getSingleTask);
      window.removeEventListener("triggerOpenAttachment", this.openAttachment);
      window.removeEventListener("triggerOpenTicket", this.openTicket);

  },
  watch: {
    taskActive() {
    },
    $route(to) {
      if (to.params.tab) {
        this.activeSubtab = to.params.tab;
      } else {
        this.activeSubtab = 'details';
      }
      if (to.params.id) {
        this.getSingleTask();
      }
    }
  },
}
</script>
<style>
.multiselect.small.open .multiselect__select:before {
  display: none;
}

.col-lg-3.col-xl-2.col-12.rightCol p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>

  <template v-if="$route && $route.matched && $route.matched.length">


    <template v-if="$route.matched[0].name === 'Documents'">
      <button class="btn btn-outline-danger btn-sm  me-3" :tooltip="$t('delete.documents')" position="left" v-if="hasSelection"
              @click="$store.commit('deleteSelected', 'documents');"><i class="fa-duotone fa-trash"></i></button>

    </template>


  </template>
</template>
  <script>

    import {mapGetters} from "vuex";

    export default {
      name: "deleteSelected",
      data() {
        return {
        };
      },
      computed:{
        ...mapGetters(['documentList']),
        hasSelection() {
          return this.documentList.selected && Object.keys(this.documentList.selected).some(id => this.documentList.selected[id]);
        }
      },
      components: {},

      methods: {

      }
    }
  </script>
  <style scoped>
  </style>

<template>
  <div class="home affiliate">


    <template v-if="demo">

      <div class="affiliateData">
        <h2>Viel Spaß beim Testen!</h2>
        <p>Du hast nun Zugang zur Testumgebung.</p>
        <p><strong>Wie es weiter geht: </strong><br>Eine nette Mitarbeiterein wird dich kontaktieren, wenn du es nicht tust!
        </p>
      </div>


    </template>
    <template v-else>
        <div class="row">
          <div class="col-4">
            <img width="200" src="https://sketch.media/images/connect/sketch_connect.svg"  >
          </div>
          <div class="col-4">
            &nbsp;
          </div>
          <div class="col-4">
            <a href="https://sketch.media" target="_blank">
            <img
              :src="logo"
              alt="logo"
              class="navbar-brand"
              style="width: 200px;margin-top: -24px;"
            /></a></div>
        </div>

        <hr>

        <div class="row">
          <div class="columns large-push-6 large-6 medium-12 small-12 wf-column">
            <p><img src="https://sketch.media/images/connect/demo_testen/demo_testen.gif"   width="750" height="429" alt="demo zugang" style="display: block; margin-left: auto; margin-right: auto;"> </p>
          </div>
          <div class="columns large-pull-6 large-6 medium-12 small-12 wf-column">
            <h2 style="color: #0088CC;">Hol dir Deinen Demozugang</h2>
            <p>
            Bist Du bereit, die Verwaltung deines Unternehmens zur Nebensache zu machen und dich voll auf deine (eigentliche) Arbeit zu konzentrieren?</p>
            <p>
            Lerne uns kennen, teste sketch.connect & entscheide selbst
            Du musst uns nicht Deine ganzen Daten hinterlegen oder ein langes Formular ausfüllen - Wir bitten dich nur um die Angabe deiner E-Mail-Adresse, damit wir dir die Zugangsdaten schicken können.</p>

            <p>Die Demoversion ist selbstverständlich vollkommen <strong>kostenfrei  und  unverbindlich.</strong></p>

            <p>Wenn Du Fragen hast, kannst du uns jederzeit <a href="https://sketch.media/connect/vorteile/#contactme" target="_blank">kontaktieren.</a></p>

            <p>Also, worauf wartest Du noch? Du hast schließlich nichts zu verlieren.</p>




          </div>
        </div>
      <div class="row">
        <div class="columns large-12 medium-12 small-12">
          <p style="text-align: center;"><img src="https://sketch.media/images/connect/pfeil_einfach.gif"  width="97" height="97" alt="pfeil einfach"><br><router-link class="btn btn-outline-primary btn-lg" style="font-size: 20px;padding: 20px 32px" to="/connect/demo">Kostenlosen Demo-Zugang erstellen</router-link></p>
        </div>
      </div>
      <ConnectDemo v-if="$route.name === 'ConnectDemo'" />
      </template>

  </div>

</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import ConnectDemo from "@/routing/core/Connect/Demo.vue";
export default {
  name: "Affiliate",

  data() {
    return {
      logo: require("@/assets/img/crm_pdf_logo_new.png"),
      demo: null,
    };
  },
  components: {ConnectDemo},
  computed: {
    ...mapGetters(['getUser', 'getApiUrl'])
  },
  created() {

    if (this.getUser && this.getUser.crm_customer_id) {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + "plugins/connect/" + this.getUser.crm_customer_id+"/demo" , {headers}).then((r) => {
        if (r && r.data && r.data.result && r.data.result.data) {
          this.demo = r.data.result.data;
        }
      });

    }
  },

};
</script>
<style>
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
</style>

<template>
  <div class="task-item headline">
    <div class="taskLineWrap">
      <div class="lineitem l-title">
        <span @click="emitSort('title')" :class="(sortDirections.title) ? 'active' : ''">Tickets</span>
        <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-subtasks">
        <span @click="emitSort('_countIssues')" :class="(sortDirections._countIssues) ? 'active' : ''"
              tooltip="Unteraufgaben" :position="'left'"><i class="fa-duotone fa-list-tree"></i></span>
        <i v-if="sortDirections._countIssues === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections._countIssues === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-attachments">
        <span @click="emitSort('_countAttachments')" :class="(sortDirections._countAttachments) ? 'active' : ''"
              tooltip="Anhang" :position="'left'"><i class="fa-duotone fa-paperclip"></i></span>
        <i v-if="sortDirections._countAttachments === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections._countAttachments === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-comments">
        <span @click="emitSort('_countComments')" :class="(sortDirections._countComments) ? 'active' : ''"
              tooltip="Kommentare" :position="'left'"><i class="fa-duotone fa-comments"></i></span>
        <i v-if="sortDirections._countComments === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections._countComments === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-prio">
        <span @click="emitSort('issuePriorityOrdering')" :class="(sortDirections.issuePriorityOrdering) ? 'active' : ''" tooltip="Priorität" :position="'left'"><i class="fa-duotone fa-star"></i></span>
        <i v-if="sortDirections.issuePriorityOrdering === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.issuePriorityOrdering === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-customer projects">
        <span @click="emitSort('projectTitle')" :class="(sortDirections.projectTitle) ? 'active' : ''">Projekt</span>
        <i v-if="sortDirections.projectTitle === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.projectTitle === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-date lastChange">
        <span @click="emitSort('_lastChange')" :class="(sortDirections._lastChange) ? 'active' : ''">Aktivität</span>
        <i v-if="sortDirections._lastChange === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections._lastChange === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-id">
        <span @click="emitSort('id')" :class="(sortDirections.id) ? 'active' : ''">ID</span>
        <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-actions ">
      </div>
      <div class="lineitem last  ">
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections: {
        title: '',
        id: '',
        _countAttachments: '',
        _countIssues: '',
        _countComments: '',
        issuePriorityOrdering: '',
        employee_id: '',
        issueStateTitle: '',
        estimated_hours: '',
        due_date: '',
        _lastChange: '',
        projectTitle: '',
      }
    }
  },
  props: {
    customer: Object,
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "getConfigs", "taskActive"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }
      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }
      // Emit the clicked type and direction
      this.$emit('sort', {type: clickedType, direction: this.sortDirections[clickedType]});
    }
  },
}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>

<template>
  <div class=" task-list"   > <p class="text-sm text-secondary" v-if="!tasks || tasks.length === 0">{{$t('tasks.list.tasksNotFound')}}</p>
      <div class="taskWrap" v-for="task in tasks"
           :key="task.id">
        <Headline :task="task"/>
      </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import $ from "jquery";
import Headline from "@/routing/core/Tasks/Layouts/Headline.vue";
import Details from "@/routing/core/Tasks/Details.vue";

export default {
  name: "TasksList",
  props: {
    tasks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["getEmployees",   "customerActive", "taskActive", "taskList" ])
  },

  data() {

    return {
      t: {
        get() {
          return this.tasks;
        },
        set(value) {
          this.tasks = value;
        }
      },
      activeTask: null,
      taskCategories: [],
      filter_query: '',
      filter_search: '',
      filter_category_id: '',
      filter_state: '',
      projectFilter: {id: '', title: this.$t('projects.allProjects')},
      stateFilter: {label: this.$t('globals.open'), value: 'open'},

      task:{},
      today: new Date().toISOString().slice(0, 10),
    };
  },
  components: {
    Headline,
    Details,
  },
  methods: {
    filter() {
      //let VueInner = this;
    },


    switchPage(type) {
      if (type === 'next' && this.taskList.offset <= this.taskList.complete) {
        this.$store.state.lists.tasks.offset = this.taskList.offset + 20;
      }
      if (type === 'previous' && this.taskList.offset >= 20) {
        this.$store.state.lists.tasks.offset = this.taskList.offset - 20;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
  },
  watch: {
    // watch router params id
    // taskActive() {
    //   if (this.taskActive && this.taskActive.id) {
    //     this.activeTask = this.taskActive.id;
    //   } else {
    //     this.activeTask = null;
    //     //this.$store.dispatch("setActiveTask", null);
    //   }
    // },

  }
};
</script>

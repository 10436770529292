// drafts.js

import router from "@/router";

export const state = {
  filters: {
    customers: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_customers'
      },
      type: {
        name: 'type',
        type: "list",
        labelType: 'name',
        value: "",
        source: "customerTypes",
      },
      country: {
        name: 'country',
        type: "list",
        labelType: 'lang_name',
        value: "",
        source: "countries",
      },
      state: {
        name: 'state',
        type: "list",
        labelType: 'name',
        value: "",
        source: "tbd",
      },
      contract: {
        name: 'contract',
        type: "list",
        labelType: 'name',
        value: "",
        source: "tbd",
      },
      creationDateFrom: {
        name: 'creationDateFrom',
        type: "datetime",
        value: "",
        source: null,
      },
      creationDateTo: {
        name: 'creationDateTo',
        type: "datetime",
        value: "",
        source: null,
      },
      umsatz: {
        name: 'umsatz',
        type: "list",
        labelType: 'name',
        value: "",
        source: "tbd",
      },
      invoiceType: {
        name: 'invoiceType',
        type: "list",
        labelType: 'name',
        value: "",
        source: "tbd",
      },
      akquise: {
        name: 'akquise',
        type: "list",
        labelType: 'name',
        value: "",
        source: "customerAcquisitions",
      },
      prio: {
        name: 'prio',
        type: "list",
        labelType: 'name',
        value: "",
        source: "customerPrio",
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "textinput",
        value: "",
        source: "title",
      },
      owner_id: {
        name: 'owner_id',
        type: "list",
        labelType: 'e_name',
        value: "",
        source: "employees",
      },
      tags: {
        name: 'tags',
        type: "multiList",
        labelType: 'name',
        value: "",
        source: "tbd",
      },
    },

    category:{
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
    },

    queues: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "doc_d_date",
        source: null
      },
    },
    employees: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
        direction: {
          name: 'direction',
          type: "list",
          value: "desc",
          source: null
        },
        ordering: {
          name: 'ordering',
          type: "list",
          value: "title",
          source: null
        },
    },
    timeConfigs: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    issueTracker: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    products: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_products'
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    tasks: {
      search:{
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
      projects:{
        name: 'project',
        type: "list",
        value: "",
        labelType: 'title',
        source: "projects",
      },
      state: {
        name: 'state',
        type: "hidden",
        labelType: 'label',
        value: "waiting",
        source:  [
          {label: 'Offen', id: 'open'},
          {label: 'Zu beantworten', id: 'waiting'},
        ],
      },





    },
    sepas: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
      active:{
        name: 'active',
        type: "list",
        value: "",
        labelType: 'name',
        source: "tbd",
      }
    },
    legals:{
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    drafts: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_drafts'
      },
      state: {
        name: 'state',
        type: "list",
        value: "",
        labelType: 'name',
        source: null
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    documents: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_documents'
      },
      docstate: {
        name: 'docstate',
        type: "list",
        value: "",
        labelType: 'label',
        source:  [
          {label: 'Aktuelle', id: '0'},
          {label: 'Archivierte', id: '1'},
        ],
      },
      owner: {
        name: 'owner',
        type: "list",
        labelType: 'e_name',
        value: "",
        source: "employees",
      },
      creationDateFrom: {
        name: 'creationDateFrom',
        type: "datetime",
        value: "",
        source: null,
      },
      creationDateTo: {
        name: 'creationDateTo',
        type: "datetime",
        value: "",
        source: null,
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "doc_d_date",
        source: null
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
    },
    events: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,

        ordering:{
          name: 'ordering',
          type: "list",
          value: "doc_d_date",
          source: null
        },
        direction:{
          name: 'direction',
          type: "list",
          value: "desc",
          source: null
        },
      },
      category_id: {
        name: 'category_id',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_events'
      },
      state: {
        name: 'state',
        type: "list",
        labelType: 'label',
        value: "",
        source:  [
          {label: 'Versteckt', id: 0},
          {label: 'Veröffentlicht', id: 1}
        ],
      },
      aktuell: {
        name: 'aktuell',
        type: "list",
        labelType: 'label',
        value: '',
        source:  [
          {label: 'Alle anzeigen', id: '3'},
          {label: 'Aktuelle anzeigen', id: '1'},
          {label: 'Vergangene anzeigen', id: '2'},
        ],
      },
      datefrom: {
        name: 'datefrom',
        type: "datetime",
        value: "",
        source: null,
      },
      dateto: {
        name: 'dateto',
        type: "datetime",
        value: "",
        source: null,
      },
      direction:{
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering:{
        name: 'ordering',
        type: "list",
        value: "doc_d_date",
        source: null
      },
    }
  },
}

export const mutations = {
  // Mutationen für drafts
}

export const actions = {
  customersFilter({dispatch, state}) {


    // set state.filters.customers from get vars

    // get GET Vars
    let vars = router.currentRoute.value.query;

    if (vars.length && vars.length > 0) {
      // reset active filters
      for (let key in state.filters.customers) {
          state.filters.customers[key].value = '';
      }
    }



    for (let key in vars) {
      if (key.indexOf('filter_') !== -1) {
        let filterName = key.replace('filter_', '');
        if (state.filters.customers[filterName] !== undefined) {
          state.filters.customers[filterName].value = vars[key];
        }
      }
    }

    router.push("/customers");

    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.customers) {
      if (state.filters.customers[a].value !== null && state.filters.customers[a].value !== '') {
        if (typeof state.filters.customers[a].value === 'object') {
          filter_query += '&filter_' + state.filters.customers[a].name + '=' + state.filters.customers[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.customers[a].name + '=' + encodeURIComponent(state.filters.customers[a].value);
        }
      }
    }
    //dispatch("customersGet", filter_query);
    const resource = 'customer';
    const apiRoute = resource;
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  tasksFilter({dispatch, state}) {
    let filter_query = '';

    this.isFiltered = true;
    let a;
    if(state.filters.tasks.search.value !== ''){
      filter_query = '&filter_search=' + encodeURIComponent(state.filters.tasks.search.value);
    } else {
    for (a in state.filters.tasks) {

      if (state.filters.tasks[a].value !== null && state.filters.tasks[a].value !== '') {
        if (typeof state.filters.tasks[a].value === 'object') {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + state.filters.tasks[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + encodeURIComponent(state.filters.tasks[a].value);
        }
      }

    }
      if (filter_query.indexOf('filter_state') === -1 ){
        filter_query += '&filter_state=waiting';
      }
    }
    // if filter_employee is empty, set default current state.user._isEmployee


    const resource = "task";
    const apiRoute = "issue";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});

  },
  tasksFilterCustomer({dispatch, state})
  {
    console.log("taskFilterCustomer?");
    let filter_query = '';

    this.isFiltered = true;
    let a;
    for (a in state.filters.tasks) {

      if (state.filters.tasks[a].value !== null && state.filters.tasks[a].value !== '') {
        if (typeof state.filters.tasks[a].value === 'object') {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + state.filters.tasks[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + encodeURIComponent(state.filters.tasks[a].value);
        }
      }

    }

    if (filter_query.indexOf('filter_state') === -1 ){
      filter_query += '&filter_state=open';
    }
    if(state.activeItems && state.activeItems.customer && state.activeItems.customer.id){
      filter_query += '&filter_customer=' + state.activeItems.customer.id;
    }
    if(state.user._isEmployee){
      filter_query += '&filter_employee=' + state.user._isEmployee;
    }
    const resource = "task";
    const apiRoute = "issue";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  documentsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.documents) {
      if (state.filters.documents[a].value !== null && state.filters.documents[a].value !== '') {
        if (typeof state.filters.documents[a].value === 'object') {
          filter_query += '&filter_' + state.filters.documents[a].name + '=' + state.filters.documents[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.documents[a].name + '=' + encodeURIComponent(state.filters.documents[a].value);
        }
      }
    }
    if(state.filters.documents.docstate.value === '' || state.filters.documents.docstate.value === null){
       filter_query += '&filter_docstate=0';
    }
    //dispatch("documentsGet", filter_query);
    const resource = "document";
    const apiRoute = "document";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  sepasFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.sepas) {
      if (state.filters.sepas[a].value !== null && state.filters.sepas[a].value !== '') {
        if (typeof state.filters.sepas[a].value === 'object') {
          filter_query += '&filter_' + state.filters.sepas[a].name + '=' + state.filters.sepas[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.sepas[a].name + '=' + encodeURIComponent(state.filters.sepas[a].value);
        }
      }
    }
    const resource = "sepa";
    const apiRoute = "sepa";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  productsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.products) {
      if (state.filters.products[a].value !== null && state.filters.products[a].value !== '') {
        if (typeof state.filters.products[a].value === 'object') {
          if(state.filters.products[a].name === 'category'){
            filter_query += '&filter_category_id=' + state.filters.products[a].value.id;
          } else {
          filter_query += '&filter_' + state.filters.products[a].name + '=' + state.filters.products[a].value.id;
          }
        } else {
          filter_query += '&filter_' + state.filters.products[a].name + '=' + encodeURIComponent(state.filters.products[a].value);
        }
      }
    }

    //dispatch("productsGet", filter_query);
    const resource = "product";
    const apiRoute = "product";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});

  },
  legalsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.legals) {
      if (state.filters.legals[a].value !== null && state.filters.legals[a].value !== '') {
        if (typeof state.filters.legals[a].value === 'object') {
          filter_query += '&filter_' + state.filters.legals[a].name + '=' + state.filters.legals[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.legals[a].name + '=' + encodeURIComponent(state.filters.legals[a].value);
        }
      }
    }
    //dispatch("legalsGet", filter_query);
    const resource = "legal";
    const apiRoute = "legal";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },

  draftsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.drafts) {
      if (state.filters.drafts[a].value !== null && state.filters.drafts[a].value !== '') {
        if (typeof state.filters.drafts[a].value === 'object') {
          if(state.filters.drafts[a].name === 'category'){
            filter_query += '&group_id=' + state.filters.drafts[a].value.id;
          } else {
          filter_query += '&filter_' + state.filters.drafts[a].name + '=' + state.filters.drafts[a].value.id;
          }
        } else {
          filter_query += '&filter_' + state.filters.drafts[a].name + '=' + encodeURIComponent(state.filters.drafts[a].value);
        }
      }
    }
    dispatch("loadDrafts", filter_query);
  },
  eventsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.events) {
      if (state.filters.events[a].value !== null && state.filters.events[a].value !== '') {
        if (typeof state.filters.events[a].value === 'object') {
          filter_query += '&filter_' + state.filters.events[a].name + '=' + state.filters.events[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.events[a].name + '=' + encodeURIComponent(state.filters.events[a].value);
        }
      }
    }

    //dispatch("eventsGet", filter_query);
    const resource = "event";
    const apiRoute = "event";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  queuesFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.queues) {
      if (state.filters.queues[a].value !== null && state.filters.queues[a].value !== '') {
        if (typeof state.filters.queues[a].value === 'object') {
          filter_query += '&filter_' + state.filters.queues[a].name + '=' + state.filters.queues[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.queues[a].name + '=' + encodeURIComponent(state.filters.queues[a].value);
        }
      }
    }
    //dispatch("queuesGet", filter_query);
    const resource = "queue";
    const apiRoute = "queue";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  setPage({state, dispatch, getters}, payload) {
    let type, list, listName, filterName;
    type = payload.type;
    list = payload.list;
    listName = payload.listName;
    filterName = payload.filterName;

    if (type === 'next' && getters[listName].offset <= getters[listName].complete) {
      state.lists[list].offset = parseInt(getters[listName].offset) + parseInt(getters[listName].limit);
    }
    if (type === 'previous' && getters[listName].offset >= getters[listName].limit) {
      state.lists[list].offset = parseInt(getters[listName].offset) - parseInt(getters[listName].limit);
    }
    dispatch(filterName);
  },
}
const routeToFilterCategoryMap = {
  'Tasks': 'tasks', // Maps the "Tasks" route to the "tasks" filter category
  'Events': 'events',
  'customers': 'customers',
  'Documents': 'documents',
  'employees': 'employees',
  'products': 'products',
  // Add other mappings as needed
};
export const getters = {

  getNumberOfActiveFilters: (state) => (routeName) => {
    let count = 0;
    const ignoreKeys = ['direction', 'ordering', 'system','state', 'employee', 'search'];
    const filterCategory = routeToFilterCategoryMap[routeName]; // Use the passed route name

    if (filterCategory && state.filters[filterCategory]) {
      for (let filterKey in state.filters[filterCategory]) {
        if (ignoreKeys.includes(filterKey)) {
          continue;
        }
        const filter = state.filters[filterCategory][filterKey];
        if ((filter.value !== '' && filter.value !== null) ||
          (Array.isArray(filter.array) && filter.array.length > 0)) {
          count++;
        }
      }
    }
    return count;
  }
}

<template>

    <ul class="navbar-nav">
      <li class="filter-item" v-for="filter in $store.state.filters.tasks">
        <component :is="'sketchFilter'+filter.type" :type="'tasks'" :filter="filter" v-if="filter"/>
      </li>
      <li class="filter-item" v-if="getActiveCustomer && getActiveCustomer.projects && getActiveCustomer.projects.length > 0">
        <VueMultiselect class="small"
                        :options="getActiveCustomer.projects"
                        :multiple="false"
                        :label="'title'"
                        :internal-search="true"
                        :close-on-select="true"
                        :clean-clear="true"
                        :valueProp="'id'"
                        :clear-on-search="true"
                        :show-labels="false"
                        v-model="$store.state.filters.tasks.projects.value"
                        @update:model-value="refreshList"
                        :placeholder="'Projekte'"
        />

      </li>
    </ul>

</template>
<script>
import sketchFilterdatetime from "@/filter/components/datetime.vue";
import sketchFilterinput from "@/filter/components/textinput.vue";
import sketchFilterlist from "@/filter/components/list.vue";
import sketchFiltermultilist from "@/filter/components/multilist.vue";

import {mapGetters} from "vuex";
import VueMultiselect from "vue-multiselect";
export default {
  name: "FiltersTasks",
  computed: {
    ...mapGetters(["getActiveCustomer"])
  },
  components: {
    sketchFilterlist, sketchFiltermultilist, sketchFilterinput, sketchFilterdatetime,
    VueMultiselect
  },
  methods: {
    refreshList() {
      this.$store.dispatch('tasksFilter');
    }
  },

};
</script>

// drafts.js

export const state = {
  pluginsContent: {
    dfb: {
      checkAnwers: {}
    }
  },
  plugins: {},
}

export const mutations = {
  setPlugins(state, payload) {
    state.plugins = payload;
  },
}

export const actions = {
  // Aktionen für drafts
}

export const getters = {
  pluginContentDfbActiveAnwsers: state => {

    let final = [];

    Object.keys(state.pluginsContent.dfb.checkAnwers).forEach(function(key) {
      if (state.pluginsContent.dfb.checkAnwers[key]) {
        final.push(key);
      }
    });


    return final;
  },
}


<template>

  <VueMultiselect class="small" v-if="filter.source !=='tbd' && options"
                  model-value="id"
                  :options="getConfigs[filter.source]"
                  :multiple="true"
                  label="name"
                  :searchable="false"
                  :close-on-select="true"
                  track-by="name"
                  @update:model-value="refreshList"
                  v-model="$store.state.filters[type][filter.name].value"
                  :placeholder="langvars[filter.name]"
  >
    <template #clear>
      <button class="clear-button btn-sm btn btn-outline-primary" v-if="$store.state.filters[type][filter.name].value" @click="clearSelection"><i class="fa-duotone fa-x fa-swap-opacity"></i></button>
    </template>
    <template slot="singleLabel" slot-scope="{ option }">
  <template v-if="option.color">
    <span :style="'background:'+option.color">{{ option[filter.labelType] }}</span>
  </template>
    <template v-else>
      {{ option[filter.labelType] }}
    </template>
  </template></VueMultiselect>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import {mapGetters} from 'vuex';
export default {
  name: "multilist",
  data() {
    return {
      customers: "",
      options: [],
      langvars: {
        category: "Kategorie wählen",
        type: "Typ wählen",
        country: "Land wählen"
      }
    };
  },
  components: {
    VueMultiselect
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs"]),
  },
  methods:{
    refreshList() {
      this.$store.dispatch(this.type+'Filter');
    },
    clearSelection() {
      this.$store.state.filters[this.type][this.filter.name].value = null;
      this.refreshList();
    }
  },
  created() {
    this.options = this.getConfigs[this.filter.source];
  }
}
</script>
<style scoped>
</style>

<template>
  <div class="input-group" v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched && $route.matched[0].name === 'customers'">
      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.customers.search.value"
             @keyup.enter="$store.dispatch('customersFilter');$store.commit('closeCustomer');" placeholder="Kunden suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.dispatch('customersFilter');$store.commit('closeCustomer')" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.customers.search.value = '';$store.dispatch('customersFilter');$store.commit('closeCustomer');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'products'">
      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.products.search.value"
             @keyup.enter="$store.dispatch('productsFilter');$store.commit('closeProduct');" placeholder="Produkte suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.dispatch('productsFilter');$store.commit('closeProduct')" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.products.search.value = '';$store.dispatch('productsFilter');$store.commit('closeProduct');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Documents'">


      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.documents.search.value"
             @keyup.enter="$store.dispatch('documentsFilter');$store.commit('closeDocument');" placeholder="Dokumente suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.dispatch('documentsFilter');$store.commit('closeDocument')" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.documents.search.value = '';$store.dispatch('documentsFilter');$store.commit('closeDocument');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Sepa'">


      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.sepas.search.value"
             @keyup.enter="$store.dispatch('sepasFilter');" placeholder="Sepa suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.dispatch('sepasFilter');" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.sepas.search.value = '';$store.dispatch('sepasFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && ($route.matched[0].name === 'Tasks' || $route.matched[0].name === 'catch')">


      <input type="text" class="form-control input-small purple" id="filter_search"
             v-model="$store.state.filters.tasks.search.value"
             @keyup.enter="$store.dispatch('tasksFilter');" placeholder="Tickets suchen ..."/>
      <div class="input-group-append me-3">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.tasks.offset = 0;$store.dispatch('tasksFilter');$store.dispatch('setTask', null);$router.push({name: 'Tasks'});" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.lists.tasks.offset = 0;$store.state.filters.tasks.projects.value = '';$store.state.filters.tasks.search.value = '';$store.dispatch('tasksFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
      <div class="btn-group">
        <button class="btn btn-sm" :class="($store.state.filters.tasks.state.value === 'waiting') ? 'btn-danger' : 'btn-outline-danger'" @click="$store.state.lists.tasks.offset = 0;$store.state.filters.tasks.state.value ='waiting';$store.dispatch('tasksFilter');">Handlung erforderlich</button>
        <button class="btn btn-sm" :class="($store.state.filters.tasks.state.value === 'open') ? 'btn-primary' : 'btn-outline-primary'"  @click="$store.state.lists.tasks.offset = 0;$store.state.filters.tasks.state.value ='open';$store.dispatch('tasksFilter');">Offen</button>
        <button class="btn btn-sm" :class="($store.state.filters.tasks.state.value === 'closed') ? 'btn-primary' : 'btn-outline-secondary'"  @click="$store.state.lists.tasks.offset = 0;$store.state.filters.tasks.state.value ='closed';$store.dispatch('tasksFilter');">Geschlossen</button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'drafts'">


      <input type="text" class="form-control input-small purple" id="filter_search"
             v-model="$store.state.filters.drafts.search.value"
             @keyup.enter="$store.dispatch('draftsFilter');" placeholder="Vorlage suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.dispatch('draftsFilter');" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.drafts.search.value = '';$store.dispatch('draftsFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Events'">


      <input type="text" class="form-control input-small purple" id="filter_search"
             v-model="$store.state.filters.events.search.value"
             @keyup.enter="$store.dispatch('eventsFilter');" placeholder="Events suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.dispatch('eventsFilter');" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.events.search.value = '';$store.dispatch('eventsFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "searchbar",
  computed: {
    ...mapActions(["logout"]),
  },
  methods: {

  },
}
</script>
<style scoped>
</style>

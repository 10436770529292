<template>
  <div class="taskLineWrap">
    <div class="d-flex">

      <div class="lineitem l-title" @click="setTask(task, 'details')">
        <template v-if="task.parent_issue && !this.$route.params.id"><i class="fa-duotone fa-list-tree"></i></template> {{ task.title }}
      </div>
      <div class="lineitem l-subtasks" @click="setTask(task, 'subtasks')" >

        <template v-if="task._countIssues > 0">
          <i class="fa-duotone fa-list-tree"></i><span> <span class="incNumber">{{ task._countIssues }}</span></span>
        </template>
      </div>
      <div class="lineitem l-attachments" @click="setTask(task, 'attachments')">
        <template v-if="task._countAttachments > 0">
          <i class="fa-duotone fa-paperclip"></i><span> <span class="incNumber">{{ task._countAttachments }}</span></span>
        </template>
      </div>

      <div class="lineitem l-comments"  @click="setTask(task, 'comments')">
        <template v-if="task._countComments > 0">
          <i class="fa-duotone fa-comments"></i><span> <span class="incNumber">{{ task._countComments }}</span></span>
        </template>
      </div>




      <div class="lineitem l-prio" @click="setEditPrio(task)"><span :tooltip="task.issuePriorityTitle"
                                                                    :position="'left'"
                                                                    :style="'color:'+task.issuePriorityColor"><i
        class="fa-duotone"
        :class="task.issuePriorityIcon"></i></span>
      </div>
      <div class="lineitem l-customer projects">
        {{task.projectTitle}}
      </div>
      <div class="lineitem l-date lastChange"
           :tooltip="'Letzte Änderung'" :position="'left'">{{ $filters.formatDateTime(task._lastChange) }}
      </div>
      <div class="lineitem l-id">
        <small>{{task.id}}</small>
      </div>
      <div class="lineitem l-avatar ml-auto">
        <template v-if="task.infos && task.infos.followers && task.infos.followers.outer">
          <small :tooltip="follower.c_firstname + ' ' + follower.c_lastname" :position="'left'" class="avatar me-1" v-for="follower in task.infos.followers.outer"><img style="max-width: 40px"
                                                                                                                                                                        :src="$filters.generateSVGFromName(follower.c_firstname + ' ' + follower.c_lastname)"
                                                                                                                                                                        class="sketchAvatar" /></small>
        </template>
        <small :tooltip="$filters.employeeName(getEmployees, task.employee_id, 'id')" :position="'left'">
        <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, task.employee_id, 'id')" class="sketchAvatar" v-if="task.employee_id"></small>
      </div>
      <div class="lineitem l-actions last">
        <template v-if="taskActive && taskActive.id === task.id">
          <i class="fa-duotone fa-x" @click="closeTask"></i>
        </template>
        <template v-else>
        <span @click="setTask(task)"><i class="fa-duotone fa-chevrons-up"></i>
        </span></template>
      </div>
    </div>

  </div>
  <Modal v-if="editPrio">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Priorität ändern</h5>
            <button type="button" class="btn-close" @click="editPrio = false; setPrio = 0; saveTask = {}"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="prio in preparePrios">
                <div class="col-3">
                  <div class="prioSetter" @click="setPrio = prio.id">
                    <template v-if="prio.id === setPrio">
                      <button type="button" class="btn btn-sm mb-2 w-100   " :position="'left'"

                              :style="'border: 0;color:#fff; background-color:'+prio.color" @click="updateTaskWith('priority_id', prio.id)"><i
                        class="fa-duotone"
                        :class="prio.icon"></i> <span>{{ prio.title }}</span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-outline-secondary btn-sm mb-2 w-100   " :position="'left'"

                              :style="'border-color: '+prio.color+';color:'+prio.color" @click="updateTaskWith('priority_id', prio.id)"><i
                        class="fa-duotone"
                        :class="prio.icon"></i> <span>{{ prio.title }}</span></button>
                    </template>

                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import router from "@/router";
import axios from "axios";

export default {
  name: "Headline",
  props: {
    task: Object,
  },
  computed: {
    ...mapGetters(["taskActive", "getEmployees", "customerActive", "getConfigs"]),
    preparePrios(){
      // remove with offical = no
      return this.getConfigs.issue_prios.filter(prio => prio.official === 'yes');
    }
  },
  methods: {
    setTask(task, subview) {
      if (subview === undefined) {
        subview = "details";
      }
      // Unset if already set:
      if (this.taskActive === task.id) {
        // this.$store.dispatch('setTask', {})
        this.$router.push({name: "Tasks"});
      } else {
        this.$router.push({name: "TasksTabs", params: {id: task.id,tab: subview}})
      }

    },
    closeTask() {
      this.$store.dispatch("tasksFilter");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Tasks"});
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    checkTask(task) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.task.customerHash;
      let postContent = {
        data: task,
        hash: hash
      }

      postContent.data.state_id = 8;
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          if (this.$route.params && this.$route.params.id) {
            this.$store.dispatch("tasksFilterCustomer");
          }
          else {
            this.$store.dispatch("tasksFilter");
          }
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    uncheckTask(task) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.task.customerHash;
      let postContent = {
        data: task,
        hash: hash
      }

      postContent.data.state_id = 5;
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          if (this.$route.params && this.$route.params.id) {
            this.$store.dispatch("tasksFilterCustomer");
          }
          else {
            this.$store.dispatch("tasksFilter");
          }
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    setEditPrio(task) {
      this.editPrio = true;
      this.setPrio = task.priority_id;
      this.saveTask = task;
    },
    updateTaskWith(type, value) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let task = this.saveTask;
      let postContent = {
        data: {},
        hash: task.customerHash
      }
      postContent.data[type] = value;
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          if (this.$route.params && this.$route.params.id) {
            this.$store.dispatch("tasksFilterCustomer");
          } else {
            this.$store.dispatch("tasksFilter");
          }
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
          this.$store.dispatch('toastSuccess', this.$t("dataSaved." + type));
          this.editPrio = false;
          this.editState = false;
          this.saveTask = {};
          this.setPrio = 0;
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
      editPrio: false,
      setPrio: 0,
      saveTask: {}
    }
  }
}
</script>

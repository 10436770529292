import Dexie from 'dexie';
import IDBExportImport from 'indexeddb-export-import'

export default {
	backup(dbName, state) {

		const db = new Dexie(dbName);
		db.open().then(function() {
			const idbDatabase = db.backendDB(); // get native IDBDatabase object from Dexie wrapper

			// export to JSON, clear database, and import from JSON
			IDBExportImport.exportToJsonString(idbDatabase, function(err, jsonString) {
				if (err) {
					console.error(err);
				} else {

					const blob = new Blob([jsonString, JSON.stringify(state)], {type: 'text/plain'})
					const e = document.createEvent('MouseEvents'),
						a = document.createElement('a');
					a.download = new Date().toLocaleString().replaceAll(".", "_").replaceAll(",", "_").replaceAll(" ", "_") + ".json";
					a.href = window.URL.createObjectURL(blob);
					a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
					e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
					a.dispatchEvent(e);

				}
			});
		}).catch(function(e) {
			console.error('Could not connect. ' + e);
		});
	},
	objectStoreNames() {
    return [
      'core', 'config', 'products', 'drafts'
    ]
	},
	dbConnect(dbName, version) {
		let functionInner = this;
		return new Promise((resolve) => {
			let openRequest = indexedDB.open(dbName, version);
			openRequest.onupgradeneeded = function () {
				let db = openRequest.result;
				let i;
				let name;
				for (i in functionInner.objectStoreNames()) {
					name = functionInner.objectStoreNames()[i];
					if (!functionInner.objectExists(db, name)) {
						functionInner.createObject(db, name);
					}
				}
			};
			openRequest.onsuccess = function (event) {
				resolve(event.target.result);
			}
			openRequest.onerror = function () {
				return false;
			}
		});
	},
	objectExists(dbName, objectStoreName) {
		dbName.objectStoreNames.contains(objectStoreName);
	},
	async createObject(db, objectStoreName) {
		return db.createObjectStore(objectStoreName, {keyPath: 'id'});
	}
	,
	async addObject(dbName, version, objectStoreName, object) {
		return new Promise((resolve, revoke) => {
			this.dbConnect(dbName, version).then(db => {
				if (db.name === dbName) {
					let transaction = db.transaction(objectStoreName, "readwrite"); // (1)
// get an object store to operate on it
					let toStore = transaction.objectStore(objectStoreName); // (2)
					// PUT TO UPDATE OR INSERT!
					let request = toStore.put(object); // (3)
					request.onsuccess = function () { // (4)
						resolve();
					};
					request.onerror = function (event) {
						revoke(event.target.error);
					};
				}
			});
		})
	},
	async removeObject(dbName, version, objectStoreName, object) {
		return new Promise((resolve, revoke) => {
			this.dbConnect(dbName, version).then(db => {
				if (db.name === dbName) {
					let transaction = db.transaction(objectStoreName, "readwrite"); // (1)
// get an object store to operate on it
					let toStore = transaction.objectStore(objectStoreName); // (2)
					// PUT TO UPDATE OR INSERT!
					let request = toStore.delete(object); // (3)
					request.onsuccess = function () { // (4)
						resolve();
					};
					request.onerror = function (event) {
						revoke(event.target.error);
					};
				}
			});
		})
	},
  async getObjectList(dbName, version, objectStoreName, revokeIfNull) {
    if (revokeIfNull === undefined) {
      revokeIfNull = false;
    }
    return new Promise((resolve, revoke) => {
      this.dbConnect(dbName, version).then(db => {
        if (db) {
          db.transaction([objectStoreName], 'readonly')
            .objectStore(objectStoreName)
            .getAll()
            .onsuccess = data => {
            if (data && data.target ) {
              if (data.target.result === null && revokeIfNull) {
                revoke();
              }
              else {

                let result = [];
                for (let i in data.target.result) {
result.push(data.target.result[i].data);
                }
                resolve(result);
              }
            } else {
              if (revokeIfNull) {
                revoke();
              }
              else {
                resolve(null)
              }
            }
          }
        }
      });
    })
  },
	async getObject(dbName, version, objectStoreName, id, revokeIfNull) {
		if (revokeIfNull === undefined) {
			revokeIfNull = false;
		}
		return new Promise((resolve, revoke) => {
			this.dbConnect(dbName, version).then(db => {
				if (db) {
					db.transaction([objectStoreName], 'readonly')
						.objectStore(objectStoreName)
						.get(id)
						.onsuccess = data => {
						if (data && data.target && data.target.result) {
							if (data.target.result.data === null && revokeIfNull) {
								revoke();
							}
							else {
								resolve(data.target.result.data);
							}
						} else {
							if (revokeIfNull) {
								revoke();
							}
							else {
								resolve(null)
							}
						}
					}
				}
			});
		})
	}
}

<template>
  <ckeditor    :editor="editor" v-model="c" :config="editorConfig" @focus="onEditorFocus" @blur="onEditorBlur" :class="(editorFocused) ? 'on-focus' : 'on-blur'"></ckeditor>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'Editor2',
  props: {
    content: null,
    customConfig: Object
  },
  data() {
    let VueInner = this;
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...this.defaultConfig,
        ...this.customConfig // merge custom configuration over the default
      },
      c: VueInner.content,
      editorFocused: false
    }
  },
  methods:{
    onEditorFocus() {
      this.editorFocused = true;
    },
    onEditorBlur() {
      setTimeout(() => {
        // Check if the editor is still the active element or part of its active path
        if (!document.activeElement.closest('.ck-editor')) {
          this.editorFocused = false;
        }
      }, 100);
    },

  },
  computed: {
    defaultConfig() {
      return {
        // Your default editor configuration here
      };
    }
  }
}
</script>

<template>
  <!-- check if filter.source is array -->
  <template v-if="Array.isArray(filter.source)">
    <VueMultiselect class="small" v-if="filter.source"
                    :id="filter.name+filter.source"
                    :options="filter.source"
                    :multiple="false"
                    :label="filter.labelType"
                    :valueProp="'id'"
                    :track-by="filter.labelType"
                    :clean-clear="true"
                    :internal-search="true"
                    :close-on-select="true"
                    :clear-on-search="true"
                    :show-labels="false"
                    v-model="$store.state.filters[type][filter.name].value"
                    @update:model-value="refreshList"
                    :placeholder="langvars[filter.name]"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <template v-if="option.color">
          <span :style="'background:'+option.color">{{ option[filter.labelType] }}</span>
        </template>
        <template v-else>
          {{ option[filter.labelType] }}
        </template>
      </template>
    </VueMultiselect>
  </template>
  <template v-else>
    <VueMultiselect class="small" v-if="filter.source !=='tbd' && options"
                    :id="filter.name+filter.source"
                    :options="getConfigs[filter.source]"
                    :multiple="false"
                    :label="filter.labelType"
                    :internal-search="true"
                    :close-on-select="true"
                    :clean-clear="true"
                    :clear-on-search="true"
                    :show-labels="false"
                    v-model="$store.state.filters[type][filter.name].value"
                    @update:model-value="refreshList"
                    :placeholder="langvars[filter.name]"
    >
      <template #clear>
        <button class="clear-button btn-sm btn btn-outline-primary" v-if="$store.state.filters[type][filter.name].value" @click="clearSelection"><i class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </template>
      <template slot="singleLabel" slot-scope="{ option }">
        <template v-if="option.color">
          <span :style="'background:'+option.color">{{ option[filter.labelType] }}</span>
        </template>
        <template v-else>
          {{ option[filter.labelType] }}
        </template>
      </template>
    </VueMultiselect>
  </template>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import {mapGetters} from 'vuex';
import multiselect from 'vue-multiselect'

export default {
  name: "list",
  data() {
    return {
      customers: "",
      options: [],
      langvars: {
        category: "Kategorie wählen",
        category_id: "Kategorie wählen",
        type: "Typ wählen",
        country: "Land wählen",
        akquise: "Akquise wählen",
        prio: "Priorität wählen",
        owner_id: "Betreuer wählen",
        state: "Status wählen",
        employee: "Mitarbeiter wählen",
        owner: "Mitarbeiter wählen",
        aktuell: 'Anzeige wählen',
        docstate: 'Status wählen',

      }
    };
  },
  components: {
    multiselect,
    VueMultiselect
  },
  methods: {
    refreshList() {
      this.$store.dispatch(this.type + 'Filter');
    },
    clearSelection() {
      this.$store.state.filters[this.type][this.filter.name].value = null;
      this.refreshList();
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs"]),
  },
  created() {
    if (Array.isArray(this.filter.source)) {
      this.options = this.filter.source;
    } else {
      this.options = this.getConfigs[this.filter.source];
    }
  },
}
</script>
<style scoped>
</style>

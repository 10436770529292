<template>
  <div class="addNewCommentWrap">
    <div class="row">
      <div class="col-xl-1 col-12">
        <p class="text-end pt-3">
          <template v-if="getUser._isEmployee">
            <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, getUser._isEmployee, 'id')"
                 class="sketchAvatar">
          </template>
          <template v-else>
            <img style="max-width: 40px" :src="$filters.generateSVGFromName(getUser.name)" class="sketchAvatar">
          </template>
        </p>
      </div>
      <div class="col-xl-11 col-12">
        <div class="commentWrap mt-3">
          <Editor2 v-model="comment.comment"></Editor2>
          <template v-if="loading">
            <button  class="btn btn-outline-primary me-3" disabled><i class="fa-duotone fa-spinner fa-spin"></i>
              Senden
            </button>
          </template>
          <template v-else>
          <button @click.once="postComment" class="btn btn-outline-primary me-3 mt-3"><i class="fa-duotone fa-paper-plane"></i>
            Senden
          </button>
          </template>
          <button @click.once="$emit('close');" class="btn btn-outline-secondary mt-3"><i class="fa-duotone fa-x"></i>
            Abbrechen
          </button>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import {mapGetters} from "vuex";

export default {
  name: "Createcomment",
  emits: ['close'],
  props: {
    taskId: ""
  },
  components: {
    Editor2
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getUser"]),
  },
  data() {
    return {
      comment: {
        task_id: this.taskId,
        comment: "",
      },
      taskItem: '',
      loading: false,
      showCommentNew: false,
    };
  },
  methods: {
    postComment() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.loading = true;
      let postContent = {
        data: this.comment,
      }
      axios.post(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment", postContent, {headers: headers})
        .then((response) => {
          this.taskItem = response.data.result.data;
          this.$store.dispatch('setTask', this.taskItem);
          this.clearComment();
          this.loading = false;
          this.$emit('close');
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("handleApiResponse", error.response);

        });
    },
    clearComment() {
      this.comment = {
        task_id: this.taskId,
        comment: "",
      };
    }
  }
}
</script>
